<template>
  <div class="operate-list">
    <el-button type="text" size="medium" @click.stop="onInfo()">操作记录</el-button>
    <!-- 编辑弹框 -->
    <el-dialog title="操作记录" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="50%">
      <!-- 表格 -->
      <el-table :data="tableData" border stripe :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }">
        <el-table-column label="操作时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.addDt || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name || "-" }}</span>
            <span>&nbsp;|&nbsp;</span>
            <span>{{ scope.row.mobile || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.operateTypeName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="内容" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.content || "-" }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <section class="table-footer">
        <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
          :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage" @current-change="onCurrentPage">
        </el-pagination>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrderOperateList,
} from "@/api/card/order";
export default {
  props: {
    childMemberCardId: {
      type: [String, Number],
      default: "",
    },
  },
  watch: {
    childMemberCardId: {
      handler(newVal) {
        this.tableParams.memberCardId = newVal;
      },
      immediate: true,
    },
  },

  mounted() {
    this.getOrderOperateList();
  },
  methods: {
  },
  data() {
    return {
      editShow: false, //弹框开关
      operateList: [],//操作记录
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        size: 10,
        merchantId: "",//商户ID
        shopId: "",//店铺ID
        memberCardId:''
      },
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  methods: {
    // 【请求】卡券操作记录
    getOrderOperateList() {
      console.log(this.tableParams);
      getOrderOperateList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getOrderOperateList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getOrderOperateList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】详情
    onInfo() {
      this.editShow = true;
      this.getOrderOperateList();
    },
  }
}
</script>

<style lang="scss" scoped>
.operate-list {
  margin: 0 .1rem;
  display: inline-flex;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}
</style>