import request from '@/utils/request'
// 省市区三级联动
export function getCityList(data) {
  return request('post', '/aps/sysArea/threeLevel', data)
}

// 获取文件列表
export function getFileList(data) {
  return request('post', '/aps/file/getFiles', data)
}

// 获取商户店铺级联列表
export function getSelectList(data) {
  return request('post', '/aps/merchant/authorizeMerCascadeVo', data)
}

// 刷新店铺选择
export function getReload(data) {
  return request('post', '/apa/user/refresh/cache', data)
}

// 店铺列表
export function getShopList(data) {
  return request('post', '/apm/tuangou/shop', data)
}