import request from '@/utils/request';
import Export from '@/utils/export';
// 卡券订单列表
export function getTableList(data) {
  return request('post', '/apm/card/order/page', data)
}

// 禁用或启用卡券
export function getStopStartCard(data) {
  return request('post', '/apm/card/order/enableOrDisable', data)
}

// 获取操作列表
export function getOrderOperateList(data) {
  return request('post', '/apm/card/order/operateLog', data)
}

// 卡券订单修改
export function getCardOrderEdit(data) {
  return request('post', '/apm/card/order/update', data)
}

// 卡券订单导出
export function getCardOrderExport(data) {
  return Export('post', '/apm/card/order/export', data, "卡券订单")
}

// 卡券批量延期
export function getCardPostpone(data) {
  return request('post', '/apm/card/order/postpone', data)
}

// 卡券详情
export function getOrderInfo(data) {
  return request('post', '/apm/card/order/info', data)
}

// 卡券退款
export function getOrderRefund(data) {
  return request('post', '/apm/card/order/refund', data)
}

// 卡券延期数量统计
export function getCardNum(data) {
  return request('post', '/apm/card/order/cardNum', data)
}