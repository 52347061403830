<template>
  <div class="page">
    <el-button type="primary" @click="onPostpone">批量延期</el-button>
    <!-- 编辑弹框 -->
    <el-dialog title="批量延期" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <h1 class="title">放假或是停业期间，可以批量操作延期，以弥补用户在用的卡券不受影响</h1>
      <p>1. 未使用的期限卡：有效期延长*天， 总可用天数不变。合计影响卡券共{{ cardNum.unusedCard }}张</p>
      <p>2. 使用中的期限卡：卡券截止日期推迟*天。 合计影响卡券共{{ cardNum.inuseCard }}张</p>
      <p>3. 次卡/时长卡：有效期延长*天。 合计影响卡券共{{ cardNum.unusedCard + cardNum.inuseCard }}张</p>
      <p>4. 已过期、已完成、已禁用的卡券不受影响。</p>
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" inline>
        <section class="form-main">
          <el-form-item label="延期门店" prop="shopId">
            <el-select v-model="params.shopId" placeholder="请选择延期门店" disabled>
              <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName"
                :value="item.shopId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="延期天数" prop="delayNum">
            <el-input v-model.trim="params.delayNum" autocomplete="off" maxlength="10" placeholder="请填写延期天数"></el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { rules } from "@/db/rules";
import { getCardPostpone, getCardNum } from "@/api/card/order"
import { getShopList } from "@/api/common"
export default {
  data() {
    return {
      // 弹框
      editShow: false, //弹框开关
      merchantId: "",
      shopId: "",
      shopList: [],
      cardNum: "",
      // from表单
      rules,
      params: {
        shopId: "",
        delayNum: "",
      },
    }
  },
  methods: {
    getShopList() {
      let data = {
        merchantId: this.merchantId
      };
      getShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.shopList = res.data;
          this.params.shopId = Number(this.shopId);
        }
      });
    },

    // 【请求】卡券延期数量统计
    getCardNum() {
      let data = {
        shopId: this.shopId
      };
      getCardNum(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.cardNum = res.data;
        }
      });
    },

    // 【请求】编辑
    getCardPostpone() {
      let data = this.params;
      getCardPostpone(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.$emit("reload");
        }
      });
    },

    // 【监听】编辑
    onPostpone() {
      let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
      if (merchantShopId) {
        this.merchantId = JSON.parse(merchantShopId)[0];
        this.shopId = JSON.parse(merchantShopId)[1];
      }
      this.getShopList();// 【请求】表格数据
      this.getCardNum();// 【请求】卡券延期数量统计
      this.editShow = true;
      this.params = {
        shopId: "",
        delayNum: "",
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },


    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getCardPostpone();
        } else {
          return false;
        }
      });
    },
  }
}
</script>


<style lang="scss" scoped>
.page {
  margin-right: .15rem;
}

.title {
  font-weight: bold;
  margin-bottom: .15rem;
}

.el-form {
  margin-top: .3rem;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>